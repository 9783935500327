import React from 'react';
import styled, { css } from 'styled-components/macro';
import { connect } from 'react-redux';

const disabled = css`
  filter: blur(10px);
  user-select: none;
  pointer-events: none;
`;

const Shroud = styled.div.attrs(props => ({
  tabIndex: props.isGated && !props.isGateReleased ? -1 : undefined,
}))`
  ${props => (props.isGated && !props.isGateReleased ? disabled : null)};
`;

/**
 * Disables the ability to access any gated content, if the gate hasn't been
 * released yet
 */
const GateShroud = props => {
  return (
    <Shroud isGated={props.isGated} isGateReleased={props.isGateReleased}>
      {props.children}
    </Shroud>
  );
};

const mapStateToProps = state => ({
  isGateReleased: state.isGateReleased,
});
export default connect(mapStateToProps)(GateShroud);
