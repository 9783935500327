import styled from 'styled-components/macro';
import { ReactComponent as LogoSvg } from '../assets/logo.svg';
import { ReactComponent as LogoFullSvg } from '../assets/logo-full.svg';

/**
 * The logo, which usually just goes in the header. This could easily go inside
 * the Header component, but this keeps it a little easier to read
 */

// export default styled.img``;

/** SVGs are already compiled as react components, so just do this */

export const LogoMark = styled(LogoSvg)`
  display: block;
  margin: 0.7em 1em;
  height: 4.5rem;
  width: auto;
  color: ${props => props.theme.colors.red[0]};

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    height: 4.75rem;
  }
`;

export const LogoFull = styled(LogoFullSvg)`
  display: block;
  margin: 0.7em 1em;
  height: 4.5rem;
  width: auto;
  color: ${props => props.theme.colors.red[0]};

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    height: 4.75rem;
  }
`;

/** If we wanted to export an actual image we could do this: */
// export default () => <Image src={Logo} alt="Logo" />;
