import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Waypoint } from 'react-waypoint';
import { createCookie } from '../../utilities';
import { Paragraph } from '../Text';
import { H2 } from '../Heading';
// import { disableGate } from '../../logic/actions';
// import GateForm from './GateForm';
import { setGateRelease } from '../../logic/actions';
import HubspotForm from '../HubspotForm';

/**
 * You might need to change this based on how much space to put between it and
 * the top of its parent Section. If the lightpaper is paged, then the waypoint
 * will fire on a gated Section before this takes effect
 */
const gateOffset = '30em';

/**
 * Sets the position and dimensions of the gate, but doesn't actually center it
 * vertically with the window. See the comment over WaypointStyled for more info
 */
const GateWrapper = styled.div`
  position: ${props => (props.isStuck ? 'fixed' : 'absolute')};
  z-index: 100000;
  top: ${props => (props.isStuck ? '50%' : gateOffset)};
  left: 50%;
  transform: translateX(-50%);
`;

/**
 * Sets the actual styles of the gate, and positions it to be center of the
 * screen. Use this for theming
 */
const GateInner = styled.div`
  padding: 0;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  transform: translateY(-50%);
  width: 80vw;
  max-width: 40em;
  height: 100%;
  max-height: 90vh;
  overflow: auto;
  display: flex;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    height: auto !important;
    overflow: auto !important;
    max-height: initial !important;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: column;
  }
`;

const StyledP = styled(Paragraph)`
  color: ${props => props.theme.colors.white};
  font-size: 0.8em;
  line-height: 1.4em;
`;

const StyledH2 = styled(H2)`
  color: ${props => props.theme.colors.white};
`;

const LeftColumn = styled.div`
  width: 40%;
  background: ${props => props.theme.colors.red[0]};
  padding: 2em;
  display: flex;
  align-items: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    width: 100%;
    padding: 1em;
  }
`;

const RightColumn = styled.div`
  width: 60%;
  padding: 2em;

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    width: 100%;
    padding: 1em;
  }
`;

/**
 * Triggers the waypoint by matching the position of the top of the Gate. It has
 * to match the top instead of the middle (like DotNavigation) because we don't
 * know the Gate's height, the Gate doesn't have a predictable percentage offset
 * based on its parent Section, and rendering it inside the Gate would cause it
 * to become position: fixed, too, which would break it
 */
const WaypointStyled = styled.span`
  position: absolute;
  z-index: -1;
  top: ${gateOffset};
`;

/**
 * We currently need to map innerRef to a real ref to make react-waypoint work
 * with styled-components v4
 */
export const WaypointTrigger = ({ innerRef, ...props }) => (
  <WaypointStyled ref={innerRef} scrollableAncestor="window" {...props} />
);

const Gate = props => {
  const [isStuck, setStuck] = useState(false);

  /** When the form is submitted, disable the gate "permanently" */
  const handleGateDisable = () => {
    createCookie('gate-released', 'true', 90);
    /** Give time for the animations to finish */
    setTimeout(() => props.setGateRelease(true), 600);
  };

  return (
    <>
      <Waypoint
        topOffset="50%"
        scrollableAncestor="window"
        onPositionChange={event => setStuck(event.currentPosition === 'above')}
      >
        <WaypointTrigger />
      </Waypoint>
      <GateWrapper isStuck={isStuck} isGateReleased={props.isGateReleased}>
        <GateInner>
          {/** Replace this with whatever third-party form you're using */}
          <LeftColumn>
            <div>
              <StyledH2>
                Would you like to see how your answers compare to those of other
                companies?
              </StyledH2>
              <StyledP>
                Please complete this short form to access the rest of Aduro’s
                Work Readiness Handbook
                {/* and see a report of poll responses at
                the end */}
                .
              </StyledP>
            </div>
          </LeftColumn>
          {/** Replace this with whatever third-party form you're using */}
          {/* <GateForm handleGateDisable={handleGateDisable} /> */}
          {/* {isFormLoading && <SpinnerStyled />} */}
          {/* {isFormLoadingInvalidated && (
              <Paragraph>
                <strong>
                  Could not load the form. Please try refreshing the page.
                </strong>
              </Paragraph>
            )} */}
          <RightColumn>
            <HubspotForm
              id="hubspot-gate"
              formId="571d0d8a-a05a-4353-a521-72934a2851e6"
              portalId="2568980"
              sfdcCampaignId="7011O000002TM2SQAW"
              redirectUrl={window.location}
              onFormSubmitted={$form => {
                handleGateDisable();
                setTimeout(() => props.setGateRelease(true), 2000);
                return false;
              }}
            ></HubspotForm>
          </RightColumn>
        </GateInner>
      </GateWrapper>
    </>
  );
};

const mapStateToProps = state => ({
  isGateReleased: state.isGateReleased,
});

const mapDispatchToProps = { setGateRelease };

export default connect(mapStateToProps, mapDispatchToProps)(Gate);
