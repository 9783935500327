import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import config from '../config';

/**
 * Generate initial head tags for the whole app. Any section-specific meta info
 * (title, description, etc) are defined in SectionHead
 */
const AppHead = ({ currentPath, content }) => {
  /**
   * In order to get the "root" URL for the lightpaper, we can take the window
   * URL then subtract the active location from the router. This will retain the
   * root path, so if your current URL was "https://example.com/base/page1", the
   * derived root URL is "https://example.com/base"
   */
  const url = window.location.href.split(currentPath)[0];

  /** Grab the meta info from the first section, if it exists */
  let metaTitle;
  let metaDescription;
  if (content[0]) {
    [{ metaTitle, metaDescription }] = content;
  }

  /** TODO: import social image and add here */
  const socialImage = null;

  return (
    <Helmet>
      <link rel="canonical" href={url} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />

      {/** Generate social meta info if we have stuff for it */}
      {metaTitle && <meta property="og:title" content={metaTitle} />}

      {metaDescription && (
        <meta property="og:description" content={metaDescription} />
      )}

      {socialImage && <meta property="og:image" content={socialImage} />}

      {/** If we don't have an image, use the default twitter card */}
      <meta
        name="twitter:card"
        content={socialImage ? 'summary_large_image' : 'summary'}
      />

      {config.twitterHandle && (
        <meta name="site" content={`@${config.twitterHandle}`} />
      )}
    </Helmet>
  );
};

AppHead.propTypes = {
  /** The location state from the connected router */
  currentPath: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      metaTitle: PropTypes.string,
      metaDescription: PropTypes.string,
    })
  ).isRequired,
};

const mapStateToProps = state => ({
  currentPath: state.router.location.pathname,
  content: state.content,
});

export default connect(mapStateToProps)(AppHead);
