import React from 'react';
import { connect } from 'react-redux';
import FullNavigation from '../components/Navigation/FullNavigation';
import DotNavigation from '../components/Navigation/DotNavigation';
import config from '../config';

const mapStateToProps = state => ({
  menuItems: state.menu,
  location: state.router.location,
});

/**
 * By conditionally rendering the nav output we can have multiple nav styles
 * that use the same global state, and choose the right one for the lightpaper
 */
const Nav = props => {
  switch (config.nav) {
    case 'full':
      return <FullNavigation {...props} />;
    case 'dot':
    default:
      return <DotNavigation {...props} />;
  }
};

export default connect(mapStateToProps)(Nav);
