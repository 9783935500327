/**
 * Searches for a specific query string and returns the value.
 *
 * @param {string} name Key to look for in the URI query
 * @return {string} result Returns a string stored in the cookie
 */
export const getUrlParameter = name => {
  const url = new URL(window.location);
  const params = new URLSearchParams(url.search);
  const value = params.get(name);
  return value;
};

/**
 * Remove a parameter from the URL if it exists.
 *
 * @param {string} name The parameter to remove
 * @return {string} The full URL with the parameter removed
 */
export const removeUrlParameter = name => {
  const url = new URL(window.location);
  let replacement = url;
  const params = new URLSearchParams(url.search);
  params.delete(name);
  replacement.search = params;
  console.log(replacement.toString());
  return replacement.toString();
};
