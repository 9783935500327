import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { Waypoint } from 'react-waypoint';

const StyledSvg = styled.div`
  position: absolute;
  user-select: none;

  /** Allow any css values to be passed in the 'styles' JSON editor */
  ${({ squiggleStyles }) =>
    squiggleStyles &&
    Object.keys(squiggleStyles).length &&
    Object.keys(squiggleStyles).map(
      key => css`
        ${key}: ${squiggleStyles[key]};
      `
    )}

  svg {
    max-width: 100%;
  }

  /** Setup the squiggle animation */
  ${props => css`
    ${props.pathSelector} {
      ${'' /* stroke-width: 4px; */}
      stroke-dasharray: ${props.squiggleLength &&
        `${props.squiggleLength} ${props.squiggleLength}`};
      stroke-dashoffset: ${
        !!props.drawSquiggle
          ? props.squiggleAnimationValues.end
          : props.squiggleAnimationValues.start
      };
      transition: ${`stroke-dashoffset ${props.theme.animations.squiggleDuration}`};
      transition-delay: ${!props.sectionTitleBackground && '1500ms'};
    }
  `}

  @media screen and (max-width: ${props => props.theme.breakpoints.large}) {
    font-size: ${props => !props.sectionTitleBackground && '1.8rem'};


    /** Allow any css values to be passed in the 'styles' JSON editor */
    ${({ squiggleStylesTablet }) =>
      squiggleStylesTablet &&
      Object.keys(squiggleStylesTablet).length &&
      Object.keys(squiggleStylesTablet).map(
        key => css`
          ${key}: ${squiggleStylesTablet[key]};
        `
      )}
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: ${props => (!props.sectionTitleBackground ? '1.6rem' : '2rem')};

    /** Allow any css values to be passed in the 'styles' JSON editor */
    ${({ squiggleStylesMobile }) =>
      squiggleStylesMobile &&
      Object.keys(squiggleStylesMobile).length &&
      Object.keys(squiggleStylesMobile).map(
        key => css`
          ${key}: ${squiggleStylesMobile[key]};
        `
      )}

  }
  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    /* font-size: ${props => props.sectionTitleBackground && '1.9rem'}; */
    /* transform: ${props =>
      props.sectionTitleBackground && 'translate(-0.5em, -6.5em)'}; */
    /** Hide the margin squiggles on mobile */
    display: ${props => !props.sectionTitleBackground && 'none'};
  }
`;

const SquiggleBlock = props => {
  const { fields, sectionTitleBackground = false } = props;
  const {
    image,
    styles: squiggleStyles,
    stylesTablet: squiggleStylesTablet,
    stylesMobile: squiggleStylesMobile,
  } = fields;
  const [svg, getSvg] = useState('');
  const [drawSquiggle, setDrawSquiggle] = useState(false);
  const svgEl = useRef(null);
  const [squiggleLength, setSquiggleLength] = useState(null);
  const [squiggleAnimationValues, setSquiggleAnimationValues] = useState({
    start: '0',
    end: '0',
  });
  /**
   * Sidebar squiggles just target the path. Heading backgrounds only want to
   * target the path.squiggle path.
   */
  const pathSelector = !!sectionTitleBackground ? 'path.squiggle' : 'path';

  useEffect(() => {
    async function fetchData() {
      await fetch(image.fields.file.url)
        .then(response => response.text())
        .then(body => getSvg(body));
    }
    image.fields.file.url && fetchData();
  }, [image.fields.file.url]);

  useEffect(() => {
    const pathEl =
      !!svg &&
      !!svgEl &&
      !!svgEl.current &&
      svgEl.current.querySelector(pathSelector);
    const pathLength = !!pathEl && pathEl.getTotalLength();

    setSquiggleLength(pathLength);
    return;
  }, [svg, svgEl, pathSelector]);

  useEffect(() => {
    !!squiggleLength &&
      setSquiggleAnimationValues({
        start: sectionTitleBackground ? -squiggleLength : squiggleLength,
        end: sectionTitleBackground ? '0' : '0',
      });
  }, [squiggleLength, sectionTitleBackground]);

  return (
    svg && (
      <Waypoint
        topOffset="20%"
        bottomOffset="20%"
        scrollableAncestor="window"
        onEnter={() => (!drawSquiggle ? setDrawSquiggle(true) : false)}
      >
        <StyledSvg
          ref={svgEl}
          squiggleStyles={squiggleStyles}
          squiggleStylesTablet={squiggleStylesTablet}
          squiggleStylesMobile={squiggleStylesMobile}
          drawSquiggle={drawSquiggle}
          squiggleLength={squiggleLength}
          sectionTitleBackground={sectionTitleBackground}
          pathSelector={pathSelector}
          squiggleAnimationValues={squiggleAnimationValues}
          dangerouslySetInnerHTML={{ __html: svg }}
        />
      </Waypoint>
    )
  );
};

export default SquiggleBlock;
