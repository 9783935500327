/**
 * Handle errors caused within Promise chains.
 *
 * An important thing to note: Using a catch() in a Promise chain catches all
 * errors thrown within that chain, both Promise rejections (due to network
 * failures or similar) and errors caused by syntax, logic, etc.
 *
 * This helper only helps detect and pass along the error, and doesn't make any
 * assumptions about what the Promise chain should do with the error. For
 * example, you might want to run a fallback network request or return a default
 * value.
 *
 * https://github.com/facebook/react/issues/7617#issuecomment-247710003
 */
export default err => {
  /* eslint-disable no-console */
  console.error(err);
  /* eslint-enable no-console */
  // throw err;
};
