import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import Section from '../../containers/Section';

/**
 * Create a Route for each section of the content that behaves like a normal
 * webpage, where only one Route is rendered at a time. This is simpler than the
 * single-page routing because we don't have to add any extra navigation or
 * scrolling abilities here
 */
const PagedRoutes = ({ menu }) => {
  return (
    <Switch>
      {menu.map((item, index) => {
        return (
          <Route
            key={item.id}
            exact
            path={item.path}
            render={() => <Section itemId={item.id} index={index} />}
          />
        );
      })}
      {/** Redirect invalid URL paths to the root */}
      <Route
        render={({ location }) => {
          /**
           * Check if there were any other Routes rendered, otherwise this will
           * run on initial page load, causing potentially valid URL paths to
           * be redirected to the root
           */
          if (menu.length) {
            /** If the current URL path doesn't match any content paths */
            const isCurrentPathValid =
              menu.findIndex(item => item.path === location.pathname) >= 0;
            return !isCurrentPathValid ? <Redirect to="/" /> : null;
          }
          return null;
        }}
      />
    </Switch>
  );
};

PagedRoutes.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default PagedRoutes;
