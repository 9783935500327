import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import Waypoint from '../Waypoint';
import Container from '../Container';
import Img from '../Image';
import { toCamelCase } from '../../utilities';

const MediaBlock = styled.div`
  width: 100%;
  margin: 4em auto;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
`;

const ImageContainer = styled.div`
  /* display: flex;
  justify-content: ${props => props.alignment}; */
`;

const StyledSvg = styled.div`
  width: ${props => props.size};
  margin: 0 auto;
  ${({ alignment }) =>
    alignment && alignment.toLowerCase() === 'left'
      ? css`
          margin-left: -8rem;
          float: left;

          @media screen and (max-width: ${props =>
              props.theme.breakpoints.medium}) {
            float: none;
            margin: 0 auto 2rem;
          }
        `
      : alignment && alignment.toLowerCase() === 'right'
      ? css`
          margin-right: -8rem;
          float: right;

          @media screen and (max-width: ${props =>
              props.theme.breakpoints.medium}) {
            float: none;
            margin: 0 auto 2rem;
          }
        `
      : null}

  svg {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  /** Display the mobile image on mobile: */
  ${props =>
    !!props.isMobile &&
    css`
      display: none;

      @media screen and (max-width: ${props =>
          props.theme.breakpoints.medium}) {
        display: block;
      }
    `}

  /** Hide the desktop image on mobile *if* a mobile alternative is provided */
  ${props =>
    !props.isMobile &&
    !!props.hasMobileAlternative &&
    css`
      display: block;

      @media screen and (max-width: ${props =>
          props.theme.breakpoints.medium}) {
        display: none;
      }
    `}
`;

const Image = styled(Img)`
  width: ${props => props.size};
  margin: 0 auto;
  ${props =>
    props.alignment && props.alignment.toLowerCase() === 'left'
      ? css`
          // margin: 0 2rem 2rem -${
            props.size ? `calc(${props.size})/2` : `4rem`
          };
          margin: 0 2rem 2rem -2rem;
          float: left;

          @media screen and (max-width: ${props =>
            props.theme.breakpoints.medium}) {
            float: none;
            margin: 0 auto 2rem;
          }
        `
      : props.alignment && props.alignment.toLowerCase() === 'right'
      ? css`
          // margin: 0 -${props.size ? `calc(${props.size})/2` : `4rem`} 2rem 0;
          margin: 0 -2rem 2rem 2rem;
          float: right;

          @media screen and (max-width: ${props =>
            props.theme.breakpoints.medium}) {
            float: none;
            margin: 0 auto 2rem;
          }
        `
      : null};
`;

const Media = ({ fields, isNested, ...rest }) => {
  const {
    image,
    imageMobile = null,
    width,
    alignment,
    containerWidth = 'normal',
  } = fields;
  const [svg, getSvg] = useState('');
  const [svgMobile, getSvgMobile] = useState('');
  const [shouldAnimate, setAnimate] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await fetch(image.fields.file.url)
        .then(response => response.text())
        .then(body => getSvg(body));
    }
    image.fields.file.url && fetchData();
  }, [image.fields.file.url]);

  useEffect(() => {
    if (
      !imageMobile ||
      !imageMobile.fields ||
      !imageMobile.fields.file ||
      !imageMobile.fields.file.url
    ) {
      return;
    }
    async function fetchData() {
      await fetch(imageMobile.fields.file.url)
        .then(response => response.text())
        .then(body => getSvgMobile(body));
    }
    imageMobile.fields.file.url && fetchData();
  }, [imageMobile]);

  const alt = image.fields && (image.fields.description || image.fields.title);

  return (
    <Waypoint
      topOffset="0"
      bottomOffset="35%"
      scrollableAncestor="window"
      onEnter={() => setAnimate(true)}
      onLeave={() => setAnimate(false)}
    >
      <MediaBlock isLastItem={rest.index + 1 === rest.numberOfBlocks}>
        <Container size={toCamelCase(containerWidth)}>
          <ImageContainer>
            {image.fields.file.contentType === 'image/svg+xml' ? (
              <StyledSvg
                alt={alt}
                alignment={alignment}
                size={width}
                shouldAnimate={shouldAnimate}
                isMobile={false}
                hasMobileAlternative={!!imageMobile}
                dangerouslySetInnerHTML={{ __html: svg }}
              />
            ) : (
              <Image
                src={image.fields.file.url}
                alt={alt}
                alignment={alignment}
                size={width}
                isMobile={false}
                hasMobileAlternative={!!imageMobile}
              />
            )}
            {!!imageMobile &&
            !!imageMobile.fields &&
            !!imageMobile.fields.file &&
            !!imageMobile.fields.file.contentType &&
            imageMobile.fields.file.contentType === 'image/svg+xml' ? (
              <StyledSvg
                alt={alt}
                alignment={alignment}
                size={width}
                shouldAnimate={shouldAnimate}
                isMobile={true}
                dangerouslySetInnerHTML={{ __html: svgMobile }}
              />
            ) : !!imageMobile ? (
              <Image
                src={imageMobile.fields.file.url}
                alt={alt}
                alignment={alignment}
                size={width}
                isMobile={true}
              />
            ) : null}
          </ImageContainer>
          {/* <CustomBarChart /> */}
        </Container>
      </MediaBlock>
    </Waypoint>
  );
};

Media.propTypes = {
  fields: PropTypes.shape({
    image: PropTypes.shape({
      sys: PropTypes.shape({}),
      fields: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
          contentType: PropTypes.string,
        }),
        title: PropTypes.string,
      }),
    }).isRequired,
    width: PropTypes.string,
    justifyContent: PropTypes.string,
  }).isRequired,
  isNested: PropTypes.bool,
};

Media.defaultProps = {
  fields: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  isNested: true,
};

export default Media;
