import { convertFromString } from '../../../utilities';

/**
 * Given a Markdown file, extract any frontmatter if it exists at the top of the
 * file.
 *
 * @param {string} markdown The raw Markdown string to search
 * @returns {Object} key:value pairs for each frontmatter line in the Markdown
 */
export const parseMarkdownFrontmatter = markdown => {
  /**
   * Look for frontmatter following this structure at the top of the file:
   *
   * ---
   * key: value
   * ---
   */
  let frontmatter = {};
  /**
   * This will strip out the separators and the rest of the content below it.
   * This one doesn't check for valid frontmatter, just anything in between the
   * separators
   */
  const frontmatterRegex = /^---\n([\s\S]*?)---\n/i;
  const frontmatterMatch = markdown.match(frontmatterRegex);
  /**
   * Match will return the full match as [0], and the regex group as [1]. In our
   * case the group is the frontmatter without the surrounding "---"
   */
  const frontmatterRaw = frontmatterMatch ? frontmatterMatch[1].trim() : null;

  /**
   * Separate the raw frontmatter into key:value pairs, assuming this structure:
   *
   * ```
   * ---
   * key: value
   * ---
   * ```
   *
   * It allows for any extra whitespace around the colon. Since this capture
   * group can have multiple matches using the global flag, we can't just
   * iterate over the match() function
   */
  const frontmatterLinesRegex = /(\w+)\s*?:\s*?([^\n]*)/gi;

  if (frontmatterRaw) {
    // /**
    //  * Regex iteration based off:
    //  * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec#Finding_successive_matches
    //  */
    // let frontmatterLinesMatches;
    // while (
    //   (frontmatterLinesMatches = frontmatterLinesRegex.exec(frontmatterRaw)) !==
    //   null
    // ) {
    //   /** The first item is always the full match, so we don't need it */
    //   const [, key, value] = frontmatterLinesMatches;
    //   frontmatter[key] = value;
    // }
    const frontmatterLinesMatches =
      frontmatterRaw.match(frontmatterLinesRegex) || [];
    frontmatterLinesMatches.map(line => {
      /** Split the line at the first colon, allowing for colons in the value */
      const [key, value] = line.split(/:(.*)/);
      const keyFormatted = key ? key.trim() : null;
      /**
       * Try and validate the datatype from the string. Values can currently
       * only be the following types:
       * 1) string (empty values will return as null)
       * 2) number
       * 3) boolean
       */
      const valueFormatted = value ? convertFromString(value.trim()) : null;
      /** Add the key:value pair to the return object */
      return keyFormatted && (frontmatter[keyFormatted] = valueFormatted);
    });
  }

  return frontmatter;
};

/**
 * Given a Markdown file, split the frontmatter from the top of the file, then
 * separate all defined content blocks into a collection.
 *
 * Content blocks are separated by this syntax:
 *   `<!-- :blockName optionalParam=true -->`
 *
 * @param {string} markdown The raw Markdown string to format
 * @returns {Array} Contains an object for each content block with all the data
 */
export const parseMarkdownCollection = markdown => {
  /** Our eventual returned data */
  let collection = [];

  /** Get the content after the frontmatter */
  const stripFrontmatterRegex = /^---\n[\s\S]*?---\n([\s\S]*?)$/i;

  const markdownMatch = markdown.match(stripFrontmatterRegex);
  /** First match is the full match, second is the capture group */
  const markdownContentFull = markdownMatch ? markdownMatch[1].trim() : null;

  /**
   * Separate the collection of content blocks from a single piece of Markdown
   * by lines following two formats:
   *
   * 1) `<!-- :blockName optionalParam=true -->`
   * 2) ```
   *    <!--
   *      :blockName
   *      optionalParam=true
   *    -->
   *    ```
   *
   * Each line with that syntax denotes a new content block. It allows for extra
   * whitespace in the separator and extra whitespace around each section
   *
   * Allows the use of normal comments without the leading colon to be included
   * in the group for the content.
   *
   * Since this uses the global flag, we'll need a way to get the capture groups
   */
  const collectionRegex = /<!--\s*?(:[\s\S]+?)\s*?-->([\s\S]*?)(?=(<!--\s*?:|$))/gi;

  if (markdownContentFull) {
    /**
     * Regex iteration based off:
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec#Finding_successive_matches
     */
    let collectionMatches;
    while (
      (collectionMatches = collectionRegex.exec(markdownContentFull)) !== null
    ) {
      /** The first item is always the full match, so we don't need it */
      const [, optionsRaw, content] = collectionMatches;

      /** Expect the content type to be one word, no dashes */
      const contentTypeMatch = optionsRaw.match(/^:(\w+)/i);
      /** First match is the full match, second is the capture group */
      const contentType = contentTypeMatch ? contentTypeMatch[1].trim() : null;

      let options = {};
      /**
       * Expect block options to look like `color=blue fullWidth=true`. Keys
       * without a value will be removed, e.g. `title= `, so just use empty
       * quotation marks, e.g. `title=''`
       *
       * Values can currently only be the following types:
       * 1) string (including an empty string with quotes: ''|"")
       * 2) number
       * 3) boolean
       *
       * TODO: allow for escaped quotes in the string, and arrays and objects as
       * non-stringified values
       */
      const optionsMatch = optionsRaw.match(/\w+\s?=\s?(\w+|'.*?'|".*?")/gi);
      if (optionsMatch && optionsMatch.length) {
        optionsMatch.map(opt => {
          /**
           * Split the key:value pair at the first instance of '=', allowing for
           * '=' to be included in the value if needed
           */
          const [key, value] = opt.split(/=(.*)/);

          const keyFormatted = key ? key.trim() : null;
          /**
           * Convert the value from a string to whatever it's supposed to be.
           * When getting empty quotes (`title=''`), the actual string we have
           * for the value is `"''"`, so clean that up first
           */
          const valueFormatted = value
            ? convertFromString(value.trim().replace(/^['"]{2}$/, ''))
            : null;

          return keyFormatted && (options[keyFormatted] = valueFormatted);
        });
      }

      /** Generate an ID for the block, used as a key in React */
      const id = `${contentType}-${collectionRegex.lastIndex}`;

      /**
       * Leave it up to the function calling this parsing function to format the
       * data how it needs to, e.g. to match Contentful's content structure.
       * Here we'll just provide an easy to read object
       */
      collection.push({
        contentType,
        id,
        options,
        content: content ? content.trim() : '',
      });
    }
  }

  /**
   * Match will return the full match as [0], and the regex group as [1]. In our
   * case the group is the Markdown without the frontmatter
   */
  return collection;
};
