import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { Waypoint } from 'react-waypoint';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';
import Image from '../Image';
import { ReactComponent as HeroBlobSvg } from '../../assets/cover-blob.svg';
import { ReactComponent as HeroSquiggle1Svg } from '../../assets/cover-squiggle-1.svg';
import { ReactComponent as HeroSquiggle2Svg } from '../../assets/cover-squiggle-2.svg';

export const HeroStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    max-width: 220rem;
    min-width: auto;
    margin: 0 auto;
    min-height: 90rem;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.medium}) {
    height: 100vh;
    min-height: 70rem;
    max-height: 100rem;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
  }
`;

const HeroContainer = styled(Container)`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${props => props.theme.colors.white};
  padding: 3em 0 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    width: 90%;
    max-width: 82rem;
  }
`;

const Background = styled.div`
  height: 100%;
  position: absolute;
  background-color: ${props => props.theme.colors.red[0]};
  left: 0;
  width: 120vw;
  z-index: -1;

  @media screen and (max-width: ${props => props.theme.breakpoints.large}) {
  }
`;

const CoverImageBg = styled.div`
  position: absolute;
  height: 70%;
  width: 60%;
  max-width: 130rem;
  background-image: ${props =>
    props.image ? `url(${props.image.fields.file.url})` : null};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  transform: translate(-41vw, 22%);
  top: 0;

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    width: 80%;
    max-width: 144rem;
    background-position: 25% 33%;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    width: 60vw;
    transform: translate(0, 0);
    max-width: 100%;
    position: absolute;
    left: -15vw;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    width: 80%;
    height: 16em;
    top: 5em;
    left: -4em;
    z-index: 10;
  }
`;

const CoverImage = styled(Image)`
  position: absolute;
  width: 60%;
  max-width: 130rem;
  transform: translate(-35vw, 10vh);
  top: 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    width: 60vw;
    max-width: 100%;
    /* transform: translate(0, 0);
    left: -15vw; */
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    width: 80%;
    top: 5em;
    left: 0;
    transform: translate(-20vw, -1vh);
    z-index: 10;
  }
`;

const HeroBody = styled.div`
  position: absolute;
  width: 130%;
  top: 45%;
  left: 0;
  padding-left: 6rem;
  z-index: 12;

  @media screen and (max-width: ${props => props.theme.breakpoints.large}) {
    padding: 6em 2em 6em;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    position: relative;
    width: 125%;
    /* top: 10%; */
    padding: 16em 2em 3em;
  }
`;

const Blob = styled(HeroBlobSvg)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 120%;
  height: auto;
  z-index: -1;

  @media screen and (max-width: ${props => props.theme.breakpoints.large}) {
    width: 90%;
  }
`;

const Squiggle1 = styled(HeroSquiggle1Svg)`
  position: absolute;
  width: 56vw;
  height: auto;
  left: -15em;
  bottom: 0;

  path {
    stroke-dashoffset: ${props => `-${props.squiggleLength}`};

    ${props =>
      !!props.drawSquiggles &&
      css`
        opacity: 1;
        stroke-dasharray: ${props.squiggleLength &&
          `${props.squiggleLength} ${props.squiggleLength}`};
        stroke-dashoffset: ${!!props.drawSquiggles
          ? '0'
          : `-${props.squiggleLength}`};
        transition: ${`stroke-dashoffset ${props.theme.animations.squiggleDuration}`};
        transition-delay: 100ms;
      `}
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    width: 42%;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    width: 100%;
    left: -1em;
  }
`;

const Squiggle2 = styled(HeroSquiggle2Svg)`
  position: absolute;
  width: 70vw;
  height: auto;
  top: 0;
  right: -1em;

  path {
    stroke-dashoffset: ${props => `-${props.squiggleLength}`};

    ${props =>
      !!props.drawSquiggles &&
      css`
        opacity: 1;
        stroke-dasharray: ${props.squiggleLength &&
          `${props.squiggleLength} ${props.squiggleLength}`};
        stroke-dashoffset: ${!!props.drawSquiggles
          ? '0'
          : `-${props.squiggleLength}`};
        transition: ${`stroke-dashoffset ${props.theme.animations.squiggleDuration}`};
        transition-delay: 3200ms;
      `}
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    width: 60%;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    width: 170%;
    right: -16em;
    top: 2em;
  }
`;

const Hero = ({ fields }) => {
  const { body, backgroundImage } = fields;

  const [drawSquiggles, setDrawSquiggles] = useState(false);
  const squiggle1Ref = useRef(null);
  const squiggle2Ref = useRef(null);
  const [squiggle1Length, setSquiggle1Length] = useState(null);
  const [squiggle2Length, setSquiggle2Length] = useState(null);

  useEffect(() => {
    if (!squiggle1Ref || !squiggle1Ref.current) {
      return;
    }
    setSquiggle1Length(
      squiggle1Ref.current.querySelector('path').getTotalLength() || null
    );
  }, [squiggle1Ref]);

  useEffect(() => {
    if (!squiggle2Ref || !squiggle2Ref.current) {
      return;
    }
    setSquiggle2Length(
      squiggle2Ref.current.querySelector('path').getTotalLength() || null
    );
  }, [squiggle2Ref]);

  return (
    <Waypoint
      topOffset="20%"
      bottomOffset="20%"
      scrollableAncestor="window"
      onEnter={() =>
        setTimeout(
          () => (!drawSquiggles ? setDrawSquiggles(true) : false),
          2000
        )
      }
    >
      <HeroStyled>
        {/* <CoverImageBg image={backgroundImage || null} /> */}
        {!!backgroundImage && (
          <CoverImage src={backgroundImage.fields.file.url} />
        )}
        <HeroContainer>
          <Background />
          <Blob />
          <HeroBody>
            <MarkdownParser>{body}</MarkdownParser>
          </HeroBody>
        </HeroContainer>
        <Squiggle1
          ref={squiggle1Ref}
          squiggleLength={squiggle1Length}
          drawSquiggles={drawSquiggles}
        />
        <Squiggle2
          ref={squiggle2Ref}
          squiggleLength={squiggle2Length}
          drawSquiggles={drawSquiggles}
        />
      </HeroStyled>
    </Waypoint>
  );
};

Hero.propTypes = {
  fields: PropTypes.shape({
    /** Body is the only required child of fields */
    body: PropTypes.string.isRequired,
    backgroundImage: PropTypes.shape({
      fields: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

export default Hero;
