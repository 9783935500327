/** A place to put miscellaneous config options */
export default {
  shouldUseRemoteContent: true,
  contentful: {
    space: 'kgkqgz66gpes',
    accessToken: 'k6e-jhRsWf0XFc3CRpYZfdmr-2av-507XhKgmOcI5cM',
  },
  isPaged: false,
  isHeaderUnderFirstBlock: false,
  /** 'dot' or 'full' */
  nav: 'dot',
  twitterHandle: 'adurolife',
};
