import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { lighten, transitions } from 'polished';
import Icon from '../Icon';
import { useSpring, animated } from 'react-spring';
import useMeasure from './useMeasure';
import Container from '../Container';
import { H4 } from '../Heading';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import contentBlocks from '../../config/contentBlocks';

const CollapsibleContainer = styled.div`
  margin-bottom: 1em;
`;

const CollapsibleIcon = styled(Icon)`
  width: 1em;
  padding: 0.2em;
  margin: 0 0.5em 0;
  color: ${props =>
    props.open ? props.theme.colors.red[0] : props.theme.colors.white};
  background-color: ${props =>
    props.open ? props.theme.colors.grey.light[1] : props.theme.colors.red[0]};
  transform-origin: center center;

  ${props =>
    transitions(
      ['transform', 'color', 'background-color'],
      `${props.theme.animations.duration} ${props.theme.animations.easing}`
    )}

  path {
    transform-origin: inherit;
    transform: ${props =>
      props.open
        ? `rotate(0deg) translateY(0)`
        : `rotate(180deg) translateY(0)`};
    ${props =>
      transitions(
        ['transform'],
        `${props.theme.animations.duration} ${props.theme.animations.easing}`
      )}
  }
`;

const CollapsibleHeading = styled.button`
  appearance: none;
  outline: none;
  border: 0;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;
  user-select: none;
  position: relative;
  z-index: 2;

  &:hover {
    ${CollapsibleIcon} {
      transform: ${props => (props.open ? `scale(1)` : `scale(1.2)`)};

      path {
        transform: ${props =>
          props.open
            ? `rotate(0deg) scale(1) translateY(0)`
            : `rotate(180deg) scale(0.9) translateY(-2px)`};
      }
    }
  }

  &:active,
  &:focus {
    background-color: ${props =>
      lighten(0.06, props.theme.colors.grey.light[1])};
  }
`;

const CollapsedText = animated(styled.div`
  overflow: hidden;
  user-select: ${props => !props.open && 'none'};
  position: relative;
  z-index: 1;
`);

const CollapsedPadding = styled.div`
  padding: 1em;
`;

const CollapsibleContent = props => {
  const { fields } = props;
  const { heading, collection } = fields;
  const [open, toggle] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [bind, { height }] = useMeasure();

  const spring = useSpring({
    opacity: open ? 1 : 0,
    /**
     * maxHeight is being used here temporarily until we can figure out how to
     * use useMeasure to get the height of a hidden element and animate the
     * height instead. Overall, this animation could be improved with some work.
     */
    maxHeight: open ? 2000 : 0,
    x: open ? 0 : -20,
  });

  return (
    <CollapsibleContainer>
      <Container>
        <CollapsibleHeading onClick={() => toggle(!open)} open={open}>
          <CollapsibleIcon open={open} inline icon={ArrowIcon} />
          {heading && <H4>{heading}</H4>}
        </CollapsibleHeading>
        <CollapsedText
          {...bind}
          open={open}
          style={{
            height: open ? 'auto' : 0,
            opacity: spring.opacity,
            maxHeight: spring.maxHeight,
            transform: spring.x.interpolate(x => `translate3d(0,${x}px,0)`),
          }}
        >
          <CollapsedPadding>
            {!!collection &&
              Array.isArray(collection) &&
              collection.map(item => {
                const ContentType = contentBlocks[item.sys.contentType.sys.id];
                return (
                  ContentType && (
                    <ContentType key={item.sys.id} {...item} isNested />
                  )
                );
              })}
          </CollapsedPadding>
        </CollapsedText>
      </Container>
    </CollapsibleContainer>
  );
};

CollapsibleContent.propTypes = {
  fields: PropTypes.shape({
    /** The hidden content within the component */
    collection: PropTypes.array,
    /** Text to use for the heading */
    heading: PropTypes.string,
  }),
};

CollapsibleContent.defaultProps = {
  fields: {
    collection: [],
    heading: '',
  },
};

export default CollapsibleContent;
