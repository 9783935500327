import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import Tippy from '@tippy.js/react';
import Icon from '../Icon';
import MarkdownParser from '../MarkdownParser';
import { ReactComponent as InformationIcon } from './information.svg';

/** Tippy styles */
const TippyStyled = styled(Tippy)`
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15),
    0 4px 80px -8px rgba(36, 40, 47, 0.25),
    0 4px 4px -2px rgba(91, 94, 105, 0.15);
  background-color: white;
  color: black;
  font-size: 1em;

  &[data-animatefill] {
    background-color: transparent;
  }

  /** The round arrow is an svg, unlike the pointed one */
  .tippy-roundarrow {
    fill: white;
  }

  /**
   * Style the correct border color for the tooltip arrow. Use three "&" to
   * override the specificity from tippy. There may be a cleaner way to do this
   */
  ${props => {
    return css`
      .tippy-popper[x-placement^=${props.placement}] &&& .tippy-arrow {
        border-${props.placement}-color: white;
      }
    `;
  }};
`;

const TooltipIcon = styled(Icon)`
  padding: 0;
  margin: 0;
  width: 0.5em;
`;

/**
 * Generate an inline Tooltip, with options for placement. You can use either
 * the `content` prop or a nested child for the tooltip's content.
 *
 * This uses the tippy.js library. See https://atomiks.github.io/tippyjs/all-options/
 * for all the possible options.
 *
 * @param {string|ReactElement} [content=''] The Tooltip text or JSX, either is valid
 * @param {string|ReactElement} [children=null] Same as content, but takes priority
 * @param {...*} props Any tippy.js props, https://atomiks.github.io/tippyjs/all-options
 */
const Tooltip = ({ content, children, ...props }) => {
  const tooltipContent = content || children;
  /**
   * If the content is a string it needs to be parsed, otherwise it's already
   * parsed before getting here
   */
  const Content = () =>
    typeof tooltipContent === 'string' ? (
      <MarkdownParser>{tooltipContent}</MarkdownParser>
    ) : (
      tooltipContent
    );

  return (
    <TippyStyled
      content={<Content />}
      theme="custom"
      arrow={true}
      arrowType="round"
      animation="shift-away"
      interactive={true}
      // trigger="click"
      /** Allow any extra props and overrides */
      {...props}
    >
      <TooltipIcon inline icon={InformationIcon} />
    </TippyStyled>
  );
};

Tooltip.propTypes = {
  /** Content to use for the Tooltip, takes priority over children */
  content: PropTypes.node,
  /** Same as content */
  children: PropTypes.node,
  /** Placement of the tooltip in relation to the icon */
  placement: PropTypes.string,
};

Tooltip.defaultProps = {
  content: '',
  children: null,
  placement: 'top',
};

export default Tooltip;
