import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

/**
 * A typical svg icon. Can be displayed inline with content or as a block-level
 *
 * To add this in a component, you'll need to import the SVG icon you want to
 * use as a React component (built-in functionality) and this Icon component.
 * Use it like this:
 *
 * `<Icon icon={IconSVG} inline />`
 */

/** Inline icon styles */
const iconInline = css`
  display: inline-block;
  vertical-align: middle;
  padding-left: 0.3em;
  margin-bottom: 0.2em;
`;

const SVGStyled = styled.svg`
  display: block;
  width: ${props => props.width};
  height: ${props => props.height};

  ${props => (props.inline ? iconInline : null)};
`;

/**
 * Wrapping the component in forwardRef helps components like Tooltip pass the
 * ref to the SVG
 */
const Icon = forwardRef(({ icon, inline, ...props }, ref) => {
  const SVG = icon;
  /**
   * Pass the props down so styled-components can use them.
   *
   * As a workaround to passing non-valid attribute values to the DOM,
   * convert `props.inline`` from boolean to a number.
   *
   * See https://reactjs.org/blog/2017/09/08/dom-attributes-in-react-16.html#changes-in-detail
   *
   * Also https://github.com/styled-components/styled-components/issues/1198#issuecomment-336621217
   */
  return <SVGStyled as={SVG} ref={ref} {...props} inline={inline ? 1 : 0} />;
});

Icon.propTypes = {
  inline: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Icon.defaultProps = {
  inline: false,
  width: '0.9em',
  height: '1em',
};

export default Icon;
