import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { textInputs, buttons } from 'polished';
// import { linkStyles } from './Links';
import { ButtonStyles } from './Links';

const FormContainer = styled.div`
  font-size: 2rem;

  .hs-form-field {
    margin: 0 3% 1em 0;
    width: 47% !important;
  }

  label,
  legend {
    display: block;
    color: ${props => props.theme.colors.black};
    font-family: ${props => props.theme.fonts.calibre};
    font-size: 0.8em;
    font-weight: 300;
    margin: 0 0 0.2em;
  }

  legend {
    color: ${props => props.theme.colors.black};
  }

  ${textInputs()},
  select {
    font-size: 0.8em;
    background: ${props => props.theme.colors.grey.light[1]};
    color: ${props => props.theme.colors.black};
    display: block;
    padding: 0.8em !important;
    width: 100%;
    border: 1px solid ${props => props.theme.colors.grey.light[0]};
    border-radius: 0;
    margin: 0;
    appearance: none;
  }

  select {
    padding: 0.6em !important;
  }

  textarea {
    resize: vertical;
  }

  ${buttons()} {
    ${ButtonStyles}

    @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
      max-width: 100%;
    }
  }

  .inputs-list {
    padding: 0;
    margin: 0;
  }

  li.hs-form-radio {
    list-style: none;

    input {
      display: inline-block;
      width: auto;
      margin-right: 1em;
    }
  }

  .submitted-message,
  .legal-consent-container {
    color: ${props => props.theme.colors.white};

    a {
      color: ${props => props.theme.colors.white};
    }
  }

  .legal-consent-container {
    font-size: 0.8em;
  }

  .hs-error-msgs {
    margin: 0.5em 0 0;
    padding: 0;
    list-style: none;
  }

  .hs-form-required {
    color: ${props => props.theme.colors.red[0]};
  }

  .hs-error-msg {
    color: ${props => props.theme.colors.red[0]};
    font-size: 0.8em;
    line-height: 1.2;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
      label,
      legend {
        font-family: ${props => props.theme.fonts.calibre};
        font-size: 1em;
      }

    .hs-form-field {
      width: 100% !important;
    }

    ${buttons()} {
      width: 100%;
    }

    fieldset {
      display: flex;
    }
  }
`;

const HubspotForm = ({ portalId, formId, id, sfdcCampaignId, ...props }) => {
  const didLoad = useRef(false);
  /**
   * Custom-load the external form script so we can track when it loads or fails
   * and make any customizations needed when it does
   */
  useEffect(() => {
    if (window.hbspt && portalId && formId && id) {
      if (!didLoad.current) {
        /** Quick fix to prevent the form loading many times */
        didLoad.current = true;

        window.hbspt.forms.create({
          portalId: portalId,
          formId: formId,
          sfdcCampaignId: sfdcCampaignId,
          target: `#${id}`,
          css: '',
          ...props,
        });
      }
    }
  }, [formId, id, sfdcCampaignId, portalId, props]);

  return <FormContainer id={id} className="hubspot-container"></FormContainer>;
};

export default HubspotForm;

// import React, { useEffect } from 'react';
// import styled from 'styled-components/macro';
// import HubspotForm from '../HubspotForm';

// const Block = styled.div`
//   background: ${props => props.theme.colors.smokem};
//   border-radius: 0.3em;
//   padding: 1.5em;
//   max-width: 40rem;
//   /* margin: 0 auto; */
// `;

// const HubspotFormBlock = ({ id, ...props }) => {
//   return (
//     <Block>
//       <HubspotForm {...props} id={id || `hubspot-form-${props.formId}`} />
//     </Block>
//   );
// };

// export default HubspotFormBlock;
