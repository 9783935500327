/**
 * Checks a string value against different data types to try and determine the
 * best way to convert it to a usable type. E.g., "true" would be converted to
 * an actual Boolean, "10" would be converted to a Number, etc.
 *
 * TODO: add conversions for objects (including json), arrays, and values like
 * null and undefined
 *
 * @param {string} string The value to test against different data types
 * @returns {*} The converted value based on what passes validation
 */
export default string => {
  if (string === 'true') {
    return true;
  }
  if (string === 'false') {
    return false;
  }
  /** Check this before the number check because Number('') returns 0 */
  if (string === '' || /\s+/.test(string)) {
    /** Chances are if it's an empty string or only whitespace, it's intended */
    return string;
  }
  /**
   * Check this after the string checks because it'll return false positives,
   * e.g. isNaN('') is false
   */
  if (!isNaN(string)) {
    /** Coerce the string to a number */
    return +string;
  }
  return string;
};
