/**
 * Sets a browser cookie with arguments to set the key/value pair and an expiration date in number of days from the date of creation.
 *
 * @param  {string} name Name of cookie.
 * @param  {string} value What the cookie is storing.
 * @param  {number} days Number of days from creation until the cookie expires.
 */

export default function(name, value, days) {
  let expires;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  } else {
    expires = '';
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
}
