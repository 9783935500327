import styled from 'styled-components/macro';

/** Set up default text block elements */
export const Paragraph = styled.p`
  margin: 0 0 1em;

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    font-size: 1.1em;
  }
`;

export const Span = styled.span``;

export const LI = styled.li``;

export const OL = styled.ol`
  /* padding: 0; */
  padding-left: 1.5em;
  list-style: none;
  counter-reset: ordered-list-counter;
  position: relative;

  ${LI}, li {
    counter-increment: ordered-list-counter;

    &:before {
      position: absolute;
      min-width: 1.15em;
      left: 0em;
      text-align: left;
      content: counter(ordered-list-counter) '. ';
      color: ${props => props.theme.colors.red[0]};
      font-weight: bold;
    }
  }
`;

export const UL = styled.ul`
  list-style: none;
  padding-left: 1.5em;

  ${LI}, li {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 0.5em;
      height: 0.5em;
      left: -1.5em;
      top: calc(0.4em);
      z-index: 0;
      background-color: ${props =>
        props.listStyle === 'checklist'
          ? props.theme.colors.white
          : props.theme.colors.red[0]};
      border: ${props => `2px solid ${props.theme.colors.red[0]}`};
    }
  }
`;
