import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { transitions } from 'polished';
import postscribe from 'postscribe';
import Container from '../Container';
import { ButtonStyles } from '../Links';
// import Media from './Media';
import Image from '../Image';
import { H4 } from '../Heading';

const PollContainer = styled(Container)`
  display: flex;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    flex-flow: column;
  }
`;

const PollBlock = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 6em 0;

  /** CrowdSignal overrides */
  .PDS_Poll[id*='PDI_container'] {
    .pds-box {
      padding: 0 !important;
      width: 100% !important;
      background-color: transparent !important;
      border: none !important;
      font-size: 1em !important;
      color: ${props => props.theme.colors.black} !important;
      border-radius: 0 !important;
      font-family: ${props => props.theme.fonts.calibre} !important;

      .pds-box-outer {
        padding: 0 !important;
      }
    }
    .pds-question {
      /** Use the contentful title field instead */
      display: none;
    }
    .pds-answer-row.pds-answer-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
    .pds-answer {
      border: none !important;
      .pds-input-label,
      .pds-feedback-label,
      .pds-total-votes {
        margin: 0.1em 0 0;
        font-size: 2.1rem !important;
        color: ${props => props.theme.colors.black};
        line-height: 1.375;
        font-weight: 400 !important;
        text-align: left;

        span {
          font-size: 1em;
        }
      }
      .pds-total-votes {
        /** Center the result summary text */
        span {
          text-align: center;
          justify-content: center;
        }
      }
      .pds-answer-input {
        align-self: initial !important;
      }
      #pds-sharing {
        display: none !important;
      }
    }
    .pds-vote {
      padding: 0 !important;
      /** magic numbers: width of .radio-input-icon + margin of .pds-answer-input */
      margin-left: calc(2em + 0.5em) !important;

      .pds-votebutton-outer {
        display: inline-block;
      }
      .pds-vote-button,
      .pds-vote-button-load {
        ${ButtonStyles};
        min-width: 7em;
        min-height: 2em;
      }
    }
    .pds-links,
    .pds-totalvotes-outer {
      display: none !important;
    }

    /** The checkbox / radio button: */
    .pds-answer-input {
      display: flex !important;
      align-items: flex-start !important;
      justify-content: flex-start !important;
      margin-right: 0.5em !important;
      width: 2em !important;
      height: 2em !important;
    }
    .pds-radiobutton,
    .pds-checkbox {
      ~ .radio-input-icon {
        transform: translateY(0.25em);
        width: 2em;
        height: 2em;
        cursor: pointer;
        display: block;
        background-color: ${props => props.theme.colors.white};
        border: ${props => `1px solid ${props.theme.colors.black}`};
        ${props =>
          transitions(
            ['background-color', 'border-color'],
            `${props.theme.animations.duration} ${props.theme.animations.easing}`
          )};

        @media screen and (max-width: ${props =>
            props.theme.breakpoints.medium}) {
          font-size: 1.8rem !important;
        }
      }
      &:checked {
        ~ .radio-input-icon {
          background-color: ${props => props.theme.colors.red[0]};
          border-color: ${props => props.theme.colors.red[0]};
        }
      }
    }

    /** RESULTS */
    &.vote-submitted {
      /** Result bars */
      .pds-box {
        .pds-answer-feedback {
          border: none !important;
          height: 3.2rem !important;
        }
        .pds-feedback-group {
          margin-bottom: 0.5em !important;

          &:last-child {
            margin-bottom: 0 !important;
          }
        }
        .pds-feedback-label {
          font-size: 0.8em !important;

          .pds-answer-text {
            font-weight: 600;
          }

          .pds-answer-text,
          .pds-feedback-result {
            width: 100%;
            float: none !important;
            display: inline-block !important;
          }
          .pds-feedback-per {
            /**
             * Correct for a silly lil' non-breaking space crowdsignal adds
             * for some reason
             */
            margin-left: -0.2em;
          }
        }
        .pds-feedback-group {
          .pds-answer-feedback-bar {
            background: ${props => props.theme.colors.red[0]} !important;
            height: 100% !important;
            transition: ${props =>
              `width ${props.theme.animations.duration} ${props.theme.animations.easing}`};
          }
          /* &:nth-of-type(even) {
          .pds-answer-feedback-bar {
          }
        } */
        }
      }
    }

    @media ${props => props.theme.breakpoints.medium} {
      .pds-box {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
      }
    }
  }
`;

const PollInner = styled.div`
  width: 60%;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    width: 100%;
  }
`;

const PollTitle = styled(H4)`
  font-size: 4rem;
`;

const PollIconContainer = styled.div`
  width: 35%;
  margin-right: 5%;
  position: relative;
  /* background-color: ${props => props.theme.colors.purple[0]}; */
  transform: translateY(-3em);

  &:before {
    content: '';
    position: absolute;
    /* width: 120%;
    height: 120%; */
    width: 20em;
    height: 20em;
    background-color: ${props => props.theme.colors.red[0]};
    right: calc(100% + 3em);
    z-index: -1;
    transform: translateY(-6em);
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    width: 100%;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    width: 80%;
    margin-right: 0;
    /* margin-left: 20%; */

    &:before {
      transform: translate(-12em, -2em);
      right: 0;
      width: 14em;
      height: 14em;
    }
  }
`;

const PollImage = styled(Image)`
  max-width: initial;
  position: relative;
  width: 200%;
  right: 100%;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    width: 100%;
    right: 6em;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    right: 0;
  }
`;

/** Script to be injected into the dom: */
const pdCallbackScript = function(json) {
  var obj = JSON.parse(json);
  var pollId = obj.id;
  var pollContainerId = 'PDI_container' + pollId;

  var currentPoll = document.getElementById(pollContainerId);

  /* eslint-disable-next-line */
  if (obj.result == 'load') {
    var pdsPollAnswerInputs = currentPoll
      ? Array.from(currentPoll.querySelectorAll('.pds-answer-input'))
      : [];
    pdsPollAnswerInputs.forEach(function(input) {
      var inputRadioItem = input.querySelector(
        '.pds-radiobutton, .pds-checkbox'
      );
      if (inputRadioItem) {
        var radioInputStyled = document.createElement('label');
        radioInputStyled.setAttribute('for', inputRadioItem.id);
        radioInputStyled.classList.add('radio-input-icon');
        input.appendChild(radioInputStyled);
        inputRadioItem.style.display = 'none';
      }
    });
    /* eslint-disable-next-line */
  } else if (obj.result == 'registered') {
    currentPoll.classList.add('vote-submitted');
  }
};

const Poll = ({ fields /*, ...rest */ }) => {
  const { title, pollId, image } = fields;
  const [pollLoaded, setPollLoaded] = useState(false);
  const pollRef = createRef(null);

  /** Load the poll */
  useEffect(() => {
    if (!pollRef || !pollRef.current || !pollId || pollLoaded) {
      return;
    }

    /** Use Postscribe to asynchronously load a callback for polldaddy */
    const pdCbScript = document.createElement('script');
    pdCbScript.type = 'text/javascript';
    pdCbScript.charset = 'utf-8';
    pdCbScript.innerHTML = `var pd_callback = ${pdCallbackScript};`;

    !!pollRef.current && postscribe(pollRef.current, pdCbScript.outerHTML);

    /** Use Postscribe to asynchronously load & execute an external script */
    const pdScript = document.createElement('script');
    pdScript.type = 'text/javascript';
    pdScript.charset = 'utf-8';
    pdScript.src = `https://secure.polldaddy.com/p/${pollId}.js`;

    !!pollRef.current && postscribe(pollRef.current, pdScript.outerHTML);

    setPollLoaded(true);
    // eslint-disable-next-line
  }, []);

  const isIe11 = !!(window.navigator.userAgent.indexOf('Trident/') > -1);

  return (
    <PollBlock isIe11={isIe11}>
      <PollContainer
      // size="wide"
      >
        {!!image &&
          !!image.fields &&
          !!image.fields.file &&
          !!image.fields.file.url && (
            <PollIconContainer>
              <PollImage src={image.fields.file.url} />
            </PollIconContainer>
          )}
        <PollInner>
          <PollTitle>{title}</PollTitle>
          <div ref={pollRef}>
            <noscript>
              <a href={`https://poll.fm/${pollId}`}>Take the poll</a>
            </noscript>
          </div>
        </PollInner>
      </PollContainer>
    </PollBlock>
  );
};

export default Poll;
