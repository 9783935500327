import { createGlobalStyle } from 'styled-components/macro';
import { normalize } from 'polished';
import '../config/fontFace.css';

/**
 * This is a one-time-use method to add global styles.
 *
 * Use for things like body and html styles, and anything that else can't fit as
 * a react component
 */
export default createGlobalStyle`
  ${normalize()}

  * {
    box-sizing: border-box;
  }

  html {
    /** Allows us to easily translate px to rem, e.g. 1.8rem = 18px */
    /** 16 * 0.625 = 10 */
    font-size: 62.5%;

    @media screen and (max-width: ${props => props.theme.breakpoints.large}) {
      /** 16 * 0.5625 = 9 */
      font-size: 56.25%;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
      /** 16 * 0.5 = 8 */
      font-size: 50%;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
      /** 16 * 0.375 = 6 */
      ${'' /* font-size: 37.5%; */}
      /** 16 * 0.4375 = 7 */
      ${'' /* font-size: 43.75% */}
    }
  }

  main {
    overflow-x: hidden;

    /** TODO: Figure out why this is breaking waypoints on desktop sizes */
    @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
      overflow-x: hidden;
    }
  }

  body {
    font-size: 2.1rem;
    font-family: ${props => props.theme.fonts.calibre};
    font-weight: 400;
    line-height: 1.6;
    overflow-x: hidden;
    color: ${props => props.theme.colors.black};
  }

  code {
    background: #fdf9e0;
    font-size: 0.8em;
    display: inline-block;
    padding: 0 0.2em;
  }
`;
