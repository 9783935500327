import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';
import { LI } from '../Text';

const DefaultBlock = styled.div`
  /* background-color: ${props =>
    props.sectionTheme === 'highlight'
      ? props.theme.colors.red[0]
      : props.sectionTheme === 'default'
      ? props.theme.colors.white
      : props.theme.colors.white};
  color: ${props =>
    props.sectionTheme === 'highlight'
      ? props.theme.colors.white
      : props.sectionTheme === 'default'
      ? props.theme.colors.black
      : props.theme.colors.black}; */
  padding: ${props => props.isNested && '1em'};

  ${props =>
    props.sectionTheme === 'highlight'
      ? css`
          background-color: ${props.theme.colors.red[0]};
          color: ${props.theme.colors.white};

          ${LI} {
            &:before {
              background-color: ${props => props.theme.colors.white};
              border-color: ${props => props.theme.colors.white};
            }
          }

          a {
            color: inherit;

            &:hover {
              color: inherit;
            }
          }
        `
      : css`
          background-color: ${props.theme.colors.white};
          color: inherit;
        `}

    /** Allow any css values to be passed in the 'styles' JSON editor */
  ${({ customStyles }) =>
    !!customStyles &&
    Object.keys(customStyles).length &&
    Object.keys(customStyles).map(
      key => css`
        ${key}: ${customStyles[key]};
      `
    )}
`;

/** The most basic type of block we can render */
const Default = ({ fields, isNested }) => {
  const {
    body,
    theme: sectionTheme = 'default',
    styles: customStyles,
  } = fields;

  return !!isNested ? (
    <DefaultBlock
      sectionTheme={sectionTheme.toLowerCase()}
      customStyles={customStyles}
      isNested
    >
      <MarkdownParser>{body}</MarkdownParser>
    </DefaultBlock>
  ) : (
    <DefaultBlock
      sectionTheme={sectionTheme.toLowerCase()}
      customStyles={customStyles}
    >
      <Container>
        <MarkdownParser>{body}</MarkdownParser>
      </Container>
    </DefaultBlock>
  );
};

Default.propTypes = {
  fields: PropTypes.shape({
    /** Body is the only required child of fields */
    body: PropTypes.string.isRequired,
    theme: PropTypes.string,
  }).isRequired,
  isNested: PropTypes.bool,
};

Default.defaultProps = {
  isNested: false,
  fields: {
    body: '',
    theme: 'default',
  },
};

export default Default;
