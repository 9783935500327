import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import PagedRoutes from '../components/Routes/PagedRoutes';
import InlineRoutes from '../components/Routes/InlineRoutes';
import config from '../config';

/** Define how redux state gets passed to Routes props */
const mapStateToProps = state => ({
  menu: state.menu,
  isHeaderUnderFirstBlock: state.header.isUnderFirstBlock,
  isPaged: config.isPaged,
  isSectionWaypointEnabled: state.isSectionWaypointEnabled,
});

const mapDispatchToProps = dispatch => ({
  /** Update the connected router with the given path */
  handleHistoryUpdate: ({ path, isScrolled }) =>
    /** Might be a bad practice to add custom options to the location change */
    dispatch(push({ pathname: path, isScrolled })),
});

/**
 * Subscribe to redux updates to make it smart. Wrap it in react-router's HOC so
 * manual updates like typing in a new URL won't keep the component from
 * updating
 */
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(config.isPaged ? PagedRoutes : InlineRoutes)
);
