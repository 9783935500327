import React from 'react';
import Markdown from 'markdown-to-jsx';
import Container from '../Container';

/** The most basic type of block we can render */
const HTMLBlock = ({ fields, ...rest }) => {
  const { html } = fields;
  return (
    <Container>
      <Markdown>{html}</Markdown>
    </Container>
  );
};

export default HTMLBlock;
