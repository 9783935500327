import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';
// import Image from '../Image';

const CalloutBlock = styled.div`
  margin: 2em;
  font-size: 1.1em;
  line-height: 1.4;
  font-weight: 700;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    margin: 2em 0;
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
  padding: 0;
`;

// const StyledImage = styled(Image)`
//   position: absolute;
//   z-index: 1;
//   height: 85%;
//   margin: 5% 0 0;
//   ${props =>
//     props.imagePosition === 'Left'
//       ? `margin-left: -20%;`
//       : `right: 0;margin-right: -15%;`}
// `;

const StyledContent = styled.div`
  position: relative;
  z-index: 2;
  font-size: 2.6rem;
  line-height: 1.4;
  /* padding: 3em 0 5em 0; */
  /* width: 65%; */
  /* ${props =>
    props.imagePosition === 'Left'
      ? `margin-left: 30%; border-left: 2px solid ${props.theme.colors.red[0]};`
      : `margin-right: 30%; border-right: 2px solid ${props.theme.colors.red[0]};`} */
`;

/** The most basic type of block we can render */
const Callout = ({ fields, ...rest }) => {
  const { content, image /*, imagePosition */ } = fields;
  return (
    <CalloutBlock image={image || null}>
      <StyledContainer>
        {/* {image && (
          <StyledImage
            src={image.fields.file.url}
            imagePosition={imagePosition}
          />
        )} */}
        <StyledContent
        // imagePosition={imagePosition}
        >
          <MarkdownParser>{content}</MarkdownParser>
        </StyledContent>
      </StyledContainer>
    </CalloutBlock>
  );
};

Callout.propTypes = {
  fields: PropTypes.shape({
    /** Body is the only required child of fields */
    content: PropTypes.string.isRequired,
    image: PropTypes.shape({
      fields: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
    imagePosition: PropTypes.string.isRequired,
  }).isRequired,
};

export default Callout;
