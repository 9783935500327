/**
 * Open the given link in a small popup window. Good for social links.
 *
 * @param {Object} event A standard window event
 */
export default event => {
  event.preventDefault();
  window.open(
    event.currentTarget.href,
    '',
    'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=500,width=500'
  );
  return false;
};
