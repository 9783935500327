import React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { Link } from 'react-router-dom';

const button_wave_open = keyframes`
  0% {
    background-position: left 100% top 70px;
  }
  to {
    background-position: left 100% top -30px;
  }
`;

const button_wave_close = keyframes`
  0% {
    background-position: left 0 top -30px;
  }
  to {
    background-position: left 0 top 70px;
  }
`;

/** Lots of button styles ripped from https://adurolife.com/ */
export const ButtonStyles = css`
  width: auto;
  padding: 1em 2.5em;
  font-size: 1.8rem !important;
  display: flex;
  font-family: calibre, sans-serif !important;
  color: ${props => props.theme.light.buttonText} !important;
  font-weight: 600;
  line-height: 1;
  box-sizing: border-box;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  text-shadow: none !important;
  border: none;
  cursor: pointer;
  transition: none !important;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSLQodC70L7QuV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKICAgICB2aWV3Qm94PSIwIDAgMTIwMCAyNjEuNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIwMCAyNjEuNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPgogICAgPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KICAgICAgICAuc3Qwe2ZpbGw6I0RCMTIxMTt9CiAgICA8L3N0eWxlPgogICAgPGc+CiAgICAgICAgPHBhdGggY2xhc3M9InN0MCIgZD0iTTYwMCwyNjEuNHYtNjkuNlYwLjFjMCwwLDU2LjEtMS41LDk4LjcsOC43Qzc0My44LDE5LjYsODQyLjksNjksOTAwLDY5czEzNC4yLTQ5LDE5Ny4zLTYwLjgKICAgICAgICAgICAgYzU5LTExLjEsNzMtNy40LDEwMi43LTguMnYyNjEuNUw2MDAsMjYxLjR6Ii8+CiAgICAgICAgPHBhdGggY2xhc3M9InN0MCIgZD0iTTQ5Nyw2MS4yQzQzNCw0OS4zLDM1NywwLjMsMzAwLDAuM1MxNDQsNDkuNiw5OSw2MC41QzU0LDcxLjMsMCw2OC4zLDAsNjguM3YxOTNoNjAwVjY5LjYKICAgICAgICAgICAgQzYwMCw2OS42LDU2MCw3Myw0OTcsNjEuMnoiLz4KICAgIDwvZz4KPC9zdmc+Cg==) !important;
  background-repeat: no-repeat !important;
  background-size: 200% 10rem !important;
  background-color: ${props => props.theme.light.button} !important;
  animation: ${button_wave_close} 0.6s ease-in-out 1;
  animation-fill-mode: forwards;
  text-decoration: none;
  position: relative;
  text-align: center;
  margin: 0;
  outline: 0;
  overflow: visible;
  white-space: pre;
  align-items: center;
  justify-content: center;

  &:hover {
    animation: ${button_wave_open} 0.6s ease-in-out 1;
    animation-fill-mode: forwards;
  }
`;

const StyledLinkBare = styled.a`
  text-decoration: none;
  color: inherit;
`;

const StyledLink = styled.a`
  color: ${props => props.theme.colors.black};
  transition: color
    ${props =>
      props.theme.animations.duration + ' ' + props.theme.animations.easing};

  &:hover,
  &:active {
    color: ${props => props.theme.colors.blue[1]};
  }
`;

export const StyledButton = styled.a`
  appearance: none;
  border: 0;

  ${ButtonStyles}
`;

const useLink = props => {
  /**
   * Determine if a link is external by constructing a URL object from the prop,
   * then comparing the hostname of the URL with the window's hostname. If the
   * `href` prop is a relative link like '/test', the window.location will be
   * used as a base. If it's an absolute URL like 'https://google.com', the base
   * will be ignored.
   *
   * Also add in a special check for the public/assets folder
   */
  const isExternal =
    new URL(props.href, window.location).hostname !== window.location.hostname;

  const attrs = isExternal
    ? /** Add extra attributes for external links */
      {
        href: props.href,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : /** Internal Link components need the "to" prop instead of "href" */
      {
        to: props.href,
      };

  return [attrs, isExternal];
};

/** Links that look like buttons */
export const Button = props => {
  const [attrs, isExternal] = useLink(props);
  return (
    <StyledButton
      {...props}
      {...attrs}
      as={isExternal ? undefined : Link}
      className={props.className}
    />
  );
};

/** An unstyled link, good for wrapping images */
export const LinkBare = props => {
  const [attrs, isExternal] = useLink(props);
  return (
    <StyledLinkBare
      {...props}
      {...attrs}
      /** If the link is internal, use a react-router Link */
      as={isExternal ? undefined : Link}
      /** Add the className so any other components can override the styling */
      className={props.className}
    >
      {props.children}
    </StyledLinkBare>
  );
};

/** Standard text links */
export default props => {
  const [attrs, isExternal] = useLink(props);
  return (
    <StyledLink
      {...props}
      {...attrs}
      /** If the link is internal, use a react-router Link */
      as={isExternal ? undefined : Link}
      /** Add the className so any other components can override the styling */
      className={props.className}
    />
  );
};
