import styled from 'styled-components/macro';
import { transparentize } from 'polished';

/** Set up default heading elements */

export const H1 = styled.h1`
  margin: 0 0 1em;
  font-size: 3em;
  line-height: 1.1;
  font-weight: 600;
  color: ${props =>
    props.color && Array.isArray(props.color)
      ? props.theme.colors[props.color][0]
      : props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.red[0]};

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 2.6em;
  }
`;

export const HeroH1 = styled(H1)`
  color: inherit;
  font-size: 11rem;
  font-weight: 700;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.large}) {
    font-size: 10.2rem;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 9.6rem;
    text-shadow: 0px 4px 14px
      ${props => transparentize(0.7, props.theme.colors.black)};
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    font-size: 8.2rem;
  }
`;

export const H2 = styled.h2`
  margin: 0 0 0.3em;
  font-size: 2em;
  line-height: 1.1;
  font-weight: 600;
  color: ${props =>
    props.color && Array.isArray(props.theme.colors[props.color])
      ? props.theme.colors[props.color][0]
      : props.color
      ? props.theme.colors[props.color]
      : 'inherit'};

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 1.8em;
  }
`;

export const H3 = styled.h3`
  margin: 0 0 0.1em;
  font-size: 1.2em;
  line-height: 1.1;
  font-weight: 600;
  color: ${props =>
    props.color && Array.isArray(props.color)
      ? props.theme.colors[props.color][0]
      : props.color
      ? props.theme.colors[props.color]
      : 'inherit'};
`;

export const H4 = styled.h4`
  margin: 0 0 0.1em;
  font-size: 1em;
  line-height: 1.1;
  font-weight: 600;
  color: ${props =>
    props.color && Array.isArray(props.color)
      ? props.theme.colors[props.color][0]
      : props.color
      ? props.theme.colors[props.color]
      : 'inherit'};
`;

export const H5 = styled.h5`
  margin: 0 0 0.1em;
  font-size: 1em;
  line-height: 1.1;
  font-weight: 600;
  color: ${props =>
    props.color && Array.isArray(props.color)
      ? props.theme.colors[props.color][0]
      : props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.grey.med[1]};

  text-transform: uppercase;
`;
