import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components/macro';

/** Event listener hook. Derived from https://usehooks.com/useEventListener */
const useEventListener = (eventName, handler, element = window) => {
  /** Create a ref that stores handler */
  const savedHandler = useRef();

  /**
   * Update ref.current value if handler changes. This allows our effect below
   * to always get latest handler without us needing to pass it in effect deps
   * array and potentially cause effect to re-run every render.
   */
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      /** Make sure element supports addEventListener */
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      /** Create event listener that calls handler function stored in ref */
      const eventListener = event => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      /** Remove event listener on cleanup  */
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    /** Re-run if eventName or element changes */
    [eventName, element]
  );
};

const Bar = styled.div`
  position: fixed;
  z-index: 10000000;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  height: 0.3em;
  font-size: 1.8rem;
  display: ${props => (props.isActive ? 'block' : 'none')};
  transform-origin: left center;
  background-color: ${props => props.theme.colors.red[0]};
`;

const Progress = props => {
  const [progress, setProgress] = useState(0);

  useEventListener(
    'scroll',
    useCallback(() => {
      if (
        props.isActive &&
        props.measureableArea &&
        props.measureableArea.current
      ) {
        /** rAF version */
        let ticking = false;

        const lastKnownY =
          window.pageYOffset - props.measureableArea.current.offsetTop;
        const totalHeight =
          props.measureableArea.current.clientHeight - window.innerHeight;

        if (!ticking) {
          requestAnimationFrame(() => {
            ticking = false;
            /** Percent format: 50 */
            // const percent = (lastKnownY / totalHeight) * 100;
            /** Decimal format: .50 */
            const percent = lastKnownY / totalHeight;
            setProgress(percent);
          });
        }
        ticking = true;

        /** non-rAF version */
        // const lastKnownY =
        //   window.pageYOffset - props.measureableArea.current.offsetTop;
        // const totalHeight =
        //   props.measureableArea.current.clientHeight - window.innerHeight;
        // const percent = (lastKnownY / totalHeight) * 100;
        // console.log(percent);
        // setProgress(percent);
      }
    }, [props.measureableArea, props.isActive])
  );

  return (
    <Bar
      isActive={props.isActive}
      // style={{ width: `${props.isActive ? progress : 0}%` }}
      style={{ transform: `scaleX(${props.isActive ? progress : 0})` }}
      // value={props.isActive ? progress : 0}
      // value={progress}
      // max={100}
    />
  );
};

export default Progress;
