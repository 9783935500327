/** Define common colors, fonts, and other branding here */
const colors = {
  white: '#ffffff',
  black: '#1d1d1d',
  red: ['#ec2626', '#ea523b', '#ed6f60', '#f0988e'],
  orange: ['#f05124', '#ee7733', '#ef8c51', '#f2ab7f'],
  yellow: ['#ea8823', '#eead49', '#f5c269', '#f7d494'],
  green: ['#058744', '#4da75f', '#5fc176', '#7ed69a'],
  blue: ['#3678bc', '#609ee9', '#7bb4f9', '#a7cdfa'],
  purple: ['#5e4ea0', '#805cca', '#9c80e4', '#c0b5fa'],
  grey: {
    light: ['#d1d2d4', '#e7e9ea'],
    med: ['#a7a9ac', '#bdbec0'],
    dark: ['#3a3a3c', '#6d6e71'],
  },
};

/** Light theme for each section */
const light = {
  primary: colors.black,
  secondary: colors.white,
  link: colors.black,
  linkHover: colors.black,
  tweet: colors.black,
  button: colors.red[0],
  buttonText: colors.white,
};

const charts = [
  colors.blue[1],
  colors.blue[3],
  colors.grey.dark[1],
  colors.grey.light[0],
  colors.orange[1],
  colors.orange[3],
  colors.yellow[1],
];

const fonts = {
  calibre: '"Calibre", Helvetica, Arial, sans-serif',
  serif: 'serif',
  sansSerif: 'sans-serif',
};

const breakpoints = {
  small: '35em',
  medium: '64em',
  large: '96em',
  xlarge: '110em',
};

const animations = {
  duration: '0.3s',
  easing: 'ease-in-out',
  squiggleDuration: '4.5s',
};

const theme = {
  colors,
  charts,
  fonts,
  breakpoints,
  animations,
  light,
};

export default theme;
