import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import Container from '../Container';
import { H1, H2 } from '../Heading';
import Image from '../Image';
import Squiggle from '../Squiggle';

const SectionTitleBlock = styled.div`
  margin: 6rem 0 2em;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
  position: relative;
  max-width: 100%;

  ${props =>
    !!props.hasSquiggle &&
    css`
      height: 18em;
      padding: 6em 0 2em;
    `};
`;

// const SectionTitleInner = styled.div`
//   height: 100%;
// `;

const DividerImage = styled(Image)`
  width: 80%;
  margin: 0 auto 1em;
`;

const TitleContainer = styled(Container)`
  position: relative;
  z-index: 10;
`;

const Eyebrow = styled(H2)`
  margin-bottom: 0;
`;

const Heading = styled(H1)`
  color: ${props =>
    !!props.hasSquiggle ? props.theme.colors.white : props.theme.colors.black};
  ${props =>
    !!props.hasSquiggle &&
    css`
      box-shadow: 0px 0px 0px ${props.theme.colors.red[0]};
      display: inline-block;
      background-color: ${props.theme.colors.red[0]};
      padding: 0em 0.15em;
      margin-left: -0.15em;
    `};
`;

const SectionTitle = ({ fields, ...rest }) => {
  const { image, sectionTitle, eyebrow, squiggle } = fields;
  const imageSrc =
    image && image.fields && image.fields.file && image.fields.file.url;
  const imageAlt =
    image && image.fields && (image.fields.description || image.fields.title);

  return (
    <SectionTitleBlock
      hasSquiggle={!!squiggle}
      isLastItem={rest.index + 1 === rest.numberOfBlocks}
    >
      {/* <SectionTitleInner> */}
      {imageSrc && <DividerImage src={imageSrc} alt={imageAlt} />}
      <TitleContainer>
        {!!squiggle && <Squiggle {...squiggle} sectionTitleBackground={true} />}
        {eyebrow && <Eyebrow color="black">{eyebrow}</Eyebrow>}
        {sectionTitle && (
          <Heading hasSquiggle={!!squiggle}>{sectionTitle}</Heading>
        )}
      </TitleContainer>
      {/* </SectionTitleInner> */}
    </SectionTitleBlock>
  );
};

SectionTitle.propTypes = {
  fields: PropTypes.shape({
    sectionTitle: PropTypes.string,
    image: PropTypes.shape({
      sys: PropTypes.shape({
        id: PropTypes.string,
      }),
      fields: PropTypes.shape({
        title: PropTypes.string,
        eyebrow: PropTypes.string,
        headingLevel: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};

SectionTitle.defaultProps = {
  fields: {
    eyebrow: null,
    sectionTitle: null,
    image: null,
    headingLevel: 'H1',
  },
};

export default SectionTitle;
