/**
 * Templatized fetch request for catching and handling errors
 *
 * @param  {string} path The URL path to use for the fetch call
 * @param  {Object} [options={}] Any options to be passed to the fetch call
 * @return {Promise} Resolves to the fetch response or an error msg if it fails
 */
export default async (path, options = {}) => {
  try {
    const response = await fetch(path, options);

    if (!response.ok || response.status >= 400) {
      /** Try to get the most descriptive error possible */
      throw new Error(
        response.status && response.statusText
          ? `${response.status} ${response.statusText}`
          : 'Something went wrong.'
      );
    }

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
